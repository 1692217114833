<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :server-items-length="options.total"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              :to="`/content-to-commerce/sites-matching/${item.id}`"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import axios from "@axios";
import { mdiPencil } from "@mdi/js";

export default {
  name: "SitesTable",
  data() {
    return {
      numberOfPages: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Plateforme",
          value: "platform",
        },
        {
          text: "Identifiant",
          value: "identifier",
        },
        {
          text: "Comment",
          value: "comment",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPencil,
      },
    };
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    "options.itemsPerPage"() {
      this.updateData();
    },
    "options.page"() {
      this.updateData();
    },
    "options.sortBy"() {
      this.updateData();
    },
    "options.sortDesc"() {
      this.updateData();
    },
    // "$store.state.c2c.siteList": function () {
    //   this.updateData();
    // },
  },
  methods: {
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0 
          ? {
            sort_by: sortBy[0],
            sort_desc: sortDesc[0],
          } 
          : {}
        ),
      };

      const { data } = await axios.get("/platform-sites/incorrect", {
        params: queryParams,
      });

      this.loading = false;
      this.items = data.items;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>
