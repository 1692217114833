<template>
  <div>
    <p class="text-2xl mb-6">Sites indéfinis à matcher</p>
    <v-col cols="12">
      <sites-matching-table></sites-matching-table>
    </v-col>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import SitesMatchingTable from "@/views/tables/admin/SitesMatchingTable";

export default {
  name: "SitesMatching",
  components: {
    SitesMatchingTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
